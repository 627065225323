import React, { useState } from "react";
import { Box, Container, Grid} from "@mui/material";
import Paragraph from "../../Paragraph"; 
// import TwitterIcon from "../../../../assets/Images/Twitter.png";
import circle from "../../../../assets/Images/circle.png";
import fire from "../../../../assets/Images/Fire.png";
import mint from "../../../../assets/Images/Mint.png";
import tax from "../../../../assets/Images/Tax.png";
import Cardimg from "../../Cardimg";
import { StyledBox } from "../../../Styles";


const Tokenomics = () => { 
const [isCopied, setIsCopied] = useState(false)
const [walletAddress, setwalletAddress] = useState("5wRSEsqSZ9PVJ2viC6BUaABPqZwX8Da9s1H2ad57pump")

const handleCopy = ()=>{
  navigator.clipboard.writeText(walletAddress)
  setIsCopied(true)
  setTimeout(() => {
    setIsCopied(false)
  }, 3000);
}
  return (
    <Box bgcolor="#2680E8" id="tokenomics"  sx={{overflow:"hidden"}}>
    <Container maxWidth="xl"> 
      <Grid container  
        // spacing={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center", 
          gap:'30px', 
          paddingBottom: "100px",
          paddingTop: { xs: "50px", md: "100px" }, 
          // paddingLeft:"20px", 
          // paddingRight:"20px", 
          marginTop:"30px",
          borderRadius:"10px"
        }}
      > 
       <Grid
            item  
            // xs={12}
            maxWidth={650}
            borderRadius="10px" 
            // maxWidth="600px"  
           
            width="100%" 
            alignContent="center" 
            padding="0px 20px 7px 0px"
            sx={{
              background:
                "#FFFFFF",
            }}
          >
            <Paragraph fontFamily="ManjariB"  fontSize="clamp(1.625rem, 1.5vw + 1.325rem, 3.125rem)"  padding="20px 10px 0px 10px"  textAlign="unset" color="#2689FB" width="100%"   >
             $NUTS TOKENOMICS
            </Paragraph>
        </Grid> 
        <StyledBox onClick={handleCopy} sx={{backgroundColor:"#2689FB",border:'1px solid white !important', borderBottom:'7px solid white !important', maxWidth:'550px !important',width:'calc(100% - 10px)', textDecoration:'none'}}>
                  <Paragraph fontFamily="ManjariB" fontSize="clamp(1rem, 0.268vw + 0.946rem, 1.188rem)" sx={{paddingTop:"6px"}} my="0px" >
                    {isCopied ? "Copied ✔" : walletAddress}
                  </Paragraph>
                </StyledBox>
        <Grid 
          item
          container 
          lg={12}
          // marginTop="70px"
          justifyContent="center"
          gap={2}
          alignItems="center"
        >
          <Cardimg data-aos="fade-up" data-aos-duration="600"  def1="TAXES" def2="0/0"  psrc={tax} />
          {/* <Cardimg data-aos="fade-up" data-aos-duration="800"  def1="MINT" def2="Revoked"  psrc={mint} />  */}
          <Cardimg data-aos="fade-up" data-aos-duration="1000"  def1="LIQUIDITY" def2="Burnt"  psrc={fire} /> 
          <Cardimg data-aos="fade-up" data-aos-duration="1200"  def1="TOTAL SUPPLY" def2="1 billion"  psrc={circle} />  
        </Grid>
      </Grid>
    </Container>
    </Box>
  );
};

export default Tokenomics;
