import { Typography } from '@mui/material';
import React from 'react';

const Paragraph = (props) => {
    const {children}=props;
    return (
        <Typography variant="body2" color="white" fontFamily="OpenSans" textAlign="left" fontWeight="lighter"  {...props}>
            {children}
        </Typography>
    );
}
 
export default Paragraph;