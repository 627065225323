import React from 'react'
import styled from '@emotion/styled'; 
import bgimg from  '../../../../assets/Images/bgimg.png' 
import p3 from  '../../../../assets/Images/p3.png'
import { Box, Container, Grid } from '@mui/material';
import Paragraph from '../../Paragraph';
import  {StyledBox}  from '../../../Styles';
import { Imagedata } from '../../LockerMenu/styles'; 
import tg from "../../../../assets/Images/tg.png";
import Twitter from "../../../../assets/Images/Twitter.png";
 

const OuterContainer = styled(Container)` 
  min-height: 80vh;    
  padding : 100px 0px;
  overflow: hidden; 
  @media(max-width: 599px){
    min-height: 60vh;    
  }
` 
const OuterBox = styled(Box)` 
  width: 100%;
  background-color:#4C9FFF;
   background-image: url(${bgimg});
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
` 
const Gridbg = styled(Grid)` 
  min-height: ${(props) => props.minHeight?props.minHeight : 'auto'}; 
 background-image: url(${(props) => props.img});
  background-position: bottom;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
` 
const Summary = () => {
     


  return (<>
    <OuterBox id="community">

    <OuterContainer maxWidth="xl">
         <Grid container display="flex" gap="80px" justifyContent="end" padding='0px 20px' >  
                {/* <Gridbg item  img={p1} lg={4}  xs={12} minHeight="300px"> </Gridbg>  */}
                <Gridbg  item  img={p3}   xs={12} sm={8} md={8} lg={7}  display="flex" flexDirection="column" justifyContent="start" gap={{sx:'0xp', lg : '10px'}} alignItems="start"     sx={{padding:{xs:'38px 38px 159px 70px', lg:'38px 79px 155px 121px !important'}}} >  
                    
                     <Paragraph   fontFamily="ManjariB" fontWeight="300" fontSize="clamp(1.188rem, 1.25vw + 0.938rem, 2.063rem)" color="black" > 
                         READY TO RULE WITH $NUTS?
                      </Paragraph> 
                      <Paragraph   fontFamily="ManjariB" fontWeight="600" fontSize="16px" color="black" > 
                         Join our socials and be a part of a thriving community!
                      </Paragraph> 
                          <Paragraph   fontFamily="ManjariB" fontWeight="600" fontSize="16px" color="black" > 
                         info@scrat.wtf
                      </Paragraph> 
                       <Box display="flex" flexDirection="row" justifyContent="start" gap="10px" alignItems="start"  >  
                       <StyledBox scale="scale(1.1)" bb ="5px solid #000" b= "1px solid #000"
                          backgroundColor="#2689FB"     href="https://t.me/ScratCoinSol" target="blank">
                         <Imagedata src={tg} style={{width:"20px", height:"20px"}} alt="Logo" />
                        </StyledBox>
                       <StyledBox scale="scale(1.1)" bb ="5px solid #000" b= "1px solid #000" backgroundColor="#2689FB" href="https://x.com/ScratCoinSol" target="blank" >
                       <Imagedata  src={Twitter} style={{width:"20px", height:"20px"}} alt="Logo" />
                       </StyledBox>
                     </Box> 
                </Gridbg>
         </Grid>
    </OuterContainer>
    </OuterBox>
    <Box bgcolor="#3693FF" display="flex" justifyContent="center">
    <Paragraph   fontFamily="ManjariB" fontWeight="600" padding="10px 0" fontSize="16px" > 
    © 2024 $NUTS. 
                      </Paragraph> 
    </Box>
  </>
  )
}

export default Summary;