import styled from "@emotion/styled";
import { Link } from "@mui/material";


const StyledBox = styled(Link)`
  min-width: 30px;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: ${(props)=> props.b ? props.b : "1px solid #fff" };
  border-bottom: ${(props)=> props.bb ? props.bb : "5px solid #fff" };
  position: relative;
  z-index: 9;
  transition: all .5s;
  cursor: pointer;
  :hover{
    transform: ${props => props.scale ? props.scale :"scale(1.05)"};
  }
`
export {StyledBox}
// khalid bashir