import { Container } from "@mui/material";
import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "About",
      link: "#about",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
    },
    {
      title: "Community",
      link: "#community",
    },
    {
      title: "Dexscreener",
      link: "https://dexscreener.com/solana/g4kkhvzpao7d6qqvubg9yz8sqjktzmtgwsswcxnzy5fr",
    },
    {
      title: "DexTools",
      link: "https://www.dextools.io/app/en/solana/pair-explorer/5wRSEsqSZ9PVJ2viC6BUaABPqZwX8Da9s1H2ad57pump?t=1730740406625",
    },
  ];
  return (
    <Container maxWidth="xl" data-aos="fade-down">
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </Container>
  );
};

export default LockerMenu;
